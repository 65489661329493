import axios from "axios";
import { getUniversalHelpers } from "../helpers";
import { isDeployedCode } from "./server-helpers";

//NOTE: If this changes, update it in mirrorAndAuditListener.ts b/c we can't bundle core in GCP listeners
export async function SendMessageToSlackChannel(p: { message: string; channel: string }) {
  if (!isDeployedCode()) {
    return;
  }

  const { app } = getUniversalHelpers();

  const dbURL = (app.options as any)?.databaseURL || "";

  const ENV = dbURL === "" ? "UNKNOWN ENV\n" : dbURL.match(/dev/i) ? "DEV ENV\n" : "PROD ENV\n";

  //TURN THIS BACK ON!!
  // await axios.post("https://hook.us1.make.com/gycxlxxre161pgto5kw7aa1kxvl9hdup", {
  //   message: ENV + p.message,
  //   channel: p.channel
  // });
}
